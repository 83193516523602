@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap');
.topbar__search:not(:focus-within) button {
  background-color: #001A71;
}
.topbar__search input {
  background-color: #AAA9C1;
  opacity:0.6;
}
.title-bar {
  background-color: #001A71;
}
.main-footer {
  background-color: #001A71;
}
.mini-footer {
  background-color: #001A71;
}
.navbar {
  background-color: #001A71;
}
body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
  font-family: 'Nunito Sans', sans-serif !important;
}
.register-form #card__tos {
  text-align: left;
}
.register-form #card__tos label {
  padding-left: 1em;
}